class StyleProp{
    property:string="";
    value:string="";
    important:boolean=false;
    constructor(property:string, value:string, important?:boolean){
        this.property=property;
        this.value=value;
        if(important) {
            this.important = important;
        }
    }
}
class StyleProps{
    [key: string]: StyleProp;
    constructor(){

    }
}

class JetendoJSS{
    private b=document.body;
    private index:number=0;
    constructor(){
    }
    parseMediaQuery(mediaQuery:string){
        let minWidth=mediaQuery.indexOf("min-width");
        let maxWidth=mediaQuery.indexOf("max-width");
        let minWidthPixels=0;
        let maxWidthPixels=0;
        if(minWidth!=-1){
            let begin=mediaQuery.indexOf(":", minWidth);
            let end=mediaQuery.indexOf(")", minWidth);
            // console.log("min:"+mediaQuery.substr(begin+1, end-(begin+1)));
            minWidthPixels=parseInt(mediaQuery.substr(begin+1, end-(begin+1)));
        }
        if(maxWidth!=-1){
            let begin=mediaQuery.indexOf(":", maxWidth);
            let end=mediaQuery.indexOf(")", maxWidth);
            // console.log("max:"+mediaQuery.substr(begin+1, end-(begin+1)));
            maxWidthPixels=parseInt(mediaQuery.substr(begin+1, end-(begin+1)));
        }
        // console.log("minMax", minWidthPixels, maxWidthPixels);
        if(minWidthPixels>0 && maxWidthPixels>0) {
            return "jss.screenWidth("+minWidthPixels+", "+maxWidthPixels+")";
        }else if(minWidthPixels>0) {
            return "jss.screenWidth("+minWidthPixels+")";
        }else{
            return "jss.screenWidth(0, "+maxWidthPixels+")";
        }
    }

    generate() {
        let h:string[]=[];
        for (let i=0; i < document.styleSheets.length; i++){
            let ss:CSSStyleSheet= document.styleSheets[i] as CSSStyleSheet;
            for(let n=0;n<ss.cssRules.length;n++){
                if(ss.cssRules[n] instanceof CSSMediaRule) {
                    let rule=ss.cssRules[n] as CSSMediaRule;
                    let mediaQuery=this.parseMediaQuery(rule.conditionText);
                    h.push(mediaQuery+'.add(');
                    for(let n2=0;n2<rule.cssRules.length;n2++) {
                        if(n2>0){
                            h[h.length-1]+=",";
                        }
                        let mediaRule=rule.cssRules[n2] as CSSStyleRule;
                        this.generateProps(h, mediaQuery, mediaRule);
                    }
                    h.push(')');
                }else{
                    let rule=ss.cssRules[n] as CSSStyleRule;
                    this.generateProps(h, "", rule);
                }
            }
        }
        console.log(h.join("\n"));
    }
    generateProps(h:string[], mediaQuery:string, rule:CSSStyleRule) {

        let props: string[] = [];
        let styles = new StyleProps();

        if (typeof rule.style != "undefined") {
            for (let g = 0; g < rule.style.length; g++) {
                let property = rule.style.item(g);
                let value = rule.style.getPropertyValue(property);
                //value=value.replace(/'/g, "");
                value = value.replace(/"/g, "'");
                if (value == "initial") {
                    continue;
                }
                let important = rule.style.getPropertyPriority(property);
                // convert property to variable case like js
                let propertyVar = this.toVariableName(property.replace(/-/g, " "));
                propertyVar = propertyVar.replace(/ /g, "");
                if (true) {
                    if (important.length > 0) {
                        styles[propertyVar] = new StyleProp(propertyVar, value, true);

                        props.push("\t." + propertyVar + '("' + value + '", true)');
                    } else {
                        styles[propertyVar] = new StyleProp(propertyVar, value);
                        props.push("\t." + propertyVar + '("' + value + '")');
                    }
                } else {
                    if (important.length > 0) {
                        props.push("\t.propImportant(_" + propertyVar + ', "' + value + '")');
                    } else {
                        props.push("\t.prop(_" + propertyVar + ', "' + value + '")');
                    }
                }
            }
        }
        if (typeof rule.selectorText != "undefined") {
            let selectors: string[] = rule.selectorText.split(",");
            let newSelectors: string[] = [];
            let firstSelector = "";
            for (let i = 0; i < selectors.length; i++) {
                let s = this.toVariableName(selectors[i].replace(/-/g, " "));
                if (s.substr(0, 2) == ".z ") {
                    s = ".jss" + s.substr(2);
                }
                s = s.replace(/ /g, "");
                if (i == 0) {
                    if (s == "*") {
                        firstSelector = "allElements";
                    } else {
                        firstSelector = s.replace(/[^a-zA-Z0-9_]*/g, "");
                        if (firstSelector == s) {
                            firstSelector += "Element";
                        } else {
                            firstSelector += "Style";
                        }
                        // firstSelector = firstSelector.charAt(0).toLowerCase() + firstSelector.substr(1);
                    }
                }
                // for (let n = 0; n < s.length; n++) {
                //     let c = s.charAt(n);
                //     if (c.replace(/[A-Z]/, "") != c) {
                //         s = s.substr(0, n) + c.toLowerCase() + s.substr(n + 1);
                //         break;
                //     }
                // }
                if (s.length > 0) {
                    newSelectors.push(s);
                }
            }
            if (mediaQuery.length > 0) {
                if (newSelectors.length > 0 && props.length > 0) {
                    // StyleBreakpoint break480=jss.screenWidth(480);
                    /*
                    jss.screenWidth(0).add( jss.select("'+newSelectors.join(", ")+'")
                    h.push('Style '+firstSelector+'=JSS.style("'+newSelectors.join(", ")+'")'+"\n"+props.join("\n")+";");
                     */
                    // h.push(mediaQuery+'.add( jss.select("'+newSelectors.join(", ")+")\n"+props.join("\n")+');');
                    //h.push('Style '+firstSelector+'=JSS.style("'+newSelectors.join(", ")+'")'+"\n"+props.join("\n")+";");
                    h.push("\t" + 'jss.select("' + newSelectors.join(", ") + '"' + ")\n" + props.join("\n"));
                }
            } else {
                if (newSelectors.length > 0 && props.length > 0) {
                    h.push('Style ' + firstSelector + '=JSS.select("' + newSelectors.join(", ") + '")' + "\n" + props.join("\n") + ";");
                }
            }
        }
    }
    toVariableName(str:string):string {
        // let s=str.replace(/\w\S*/g, function(txt){
        //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        // });
        // s=s.charAt(0).toLowerCase()+s.substring(1);
        // return s;
        return str;
    }
}